
.faq-container {
    min-height: 100vh;
    padding: 3rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;
  }
  
  .faq-inner {
    max-width: 768px;
    margin: auto;
    text-align: center;
  }
  
  .faq-title {
    font-size: 1.875rem;
    margin-top: 70px;
    font-weight: 800;
    color: #1a202c;
    margin-bottom: 2rem;
  }
  
  .faq-subtitle {
    font-size: 1.25rem;
    color: #718096;
    margin-bottom: 3rem;
  }
  
  .accordion-item {
    background-color: white; /* Fondo blanco para cada cuadro de pregunta */
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
  }
  
  .accordion-trigger {
    text-align: left;
    padding: 1rem 1.5rem;
    font-size: 1.125rem;
    font-weight: 500;
    font-family: Anton, sans-serif;
    color: #1a202c;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.2s ease;
    background-color: white;
  }
  
  
  .accordion-content {
    padding: 0.5rem 1.5rem 1rem;
    color: #4a5568;
    font-size: 1rem;
  }
  