/* AcercaDeNosotros.css */
.about-container {
  min-height: 100vh;
  background-color: #f9f9f9;
  padding: 3rem 1rem;
  max-width: 1200px;
  margin: auto;
  font-family: Arial, sans-serif;
  justify-content: center;
  align-items: center;
}

.about-intro, .about-cta {
  text-align: center;
  margin-bottom: 2rem;
}

.about-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: black;
  margin-top: 70px;
  margin-bottom: 20px;
}

.about-description, .cta-description {
  font-size: 1.25rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;
}

.about-mission-vision {
  display: flex;
  flex-direction: column; /* Cambia a una columna */
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.about-card {
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra el contenido horizontalmente */
  justify-content: center; /* Centra el contenido verticalmente */
  text-align: center; /* Centra el texto */
}

.about-card-title {
  font-size: 1.5rem;
  color: black;
  margin-bottom: 0.5rem;
}

.about-card-content {
  color: #666;
  font-size: 1rem;
}

.about-services {
  text-align: center;
  margin-bottom: 2rem;
}

.about-section-title {
  font-size: 2rem;
  color: black;
  margin-bottom: 1rem;
}

.services-grid {
  display: flex;
  flex-direction: column; /* Cambia a una columna */
  gap: 1.5rem;
}

.service-card {
  background: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: black;
  display: flex;
  align-items: center; 
  justify-content: center; 
  text-align: center; 
}

.service-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.service-description {
  color: #666;
  font-size: 1rem;
}

.about-values {
  text-align: center;
  margin-bottom: 2rem;
}

.values-list {
  list-style: disc;
  padding-left: 1.5rem;
  color: #666;
  text-align: left;
  margin: auto;
  max-width: 600px;
}

.about-cta {
  text-align: center;
}

.cta-title {
  font-size: 2rem;
  color: black;
  margin-bottom: 0.5rem;
}

.cta-button {
  margin-top: 25px;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.cta-button:hover {
  background-color: #555;
}
