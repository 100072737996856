/* servicios.css */

.services-container {
    min-height: 100vh;
    background-color:#f9f9f9;
    padding: 3rem 1rem;
    max-width: 1200px;
    margin: auto;
    font-family: Arial, sans-serif;
    justify-content: center;
    align-items: center;
  }
  
  .services-intro, .services-cta {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .services-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: black;
    margin-top: 70px;
    margin-bottom: 20px;
  }
  
  .services-description, .cta-description {
    font-size: 1.25rem;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .services-list {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .services-section-title {
    font-size: 2rem;
    color: black;
    margin-bottom: 1rem;
  }
  
  .services-grid {
    display: flex;
    flex-direction: column; /* Coloca los servicios en una columna */
    gap: 1.5rem;
  }
  
  .service-card {
    background: #f9f9f9;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra el contenido horizontalmente */
    text-align: center; /* Centra el texto */
  }
  
  .service-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .service-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  
  .service-description {
    color: #666;
    font-size: 1rem;
  }
  
  .services-cta {
    text-align: center;
  }
  
  .cta-title {
    font-size: 2rem;
    color: black;
    margin-bottom: 0.5rem;
  }
  
  .cta-button {
    margin-top: 25px;
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    background-color: black;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .cta-button:hover {
    background-color: #555;
  }
  

  .services-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .service-card {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
  }
  
  .service-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .service-text {
    flex: 1;
  }
  